/**
 * Created by dpardell on 23/11/2015.
 */

angular
    .module('annexaApp')
    .controller('delegDashboardController', ['$localStorage', '$scope', 'api', 'apiSign', 'CommonService', '$state', '$http', '$rootScope', 'LoginService', 'HeaderService','RestService', '$timeout', 'Language', function ($localStorage, $scope, api, apiSign, CommonService, $state, $http, $rootScope, LoginService, HeaderService, RestService, $timeout, Language) {

        $http.defaults.headers.common['x-auth-token'] = $localStorage.LoggedUserToken;

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.dashboard'){
                $scope.getDelegationsDash();
                $scope.getDelegations();
            }
        });
        $scope.openInputStartDate = false;
        $scope.openInputEndDate = false;
        $scope.delegateModal = {userName:'', user:{}, minDate:new Date(), dateStart:new Date(), dateEnd:new Date(), error:false, errorDescripcio:'', active:false, activeRebudes:false, delgacioFeta:{}, delegacionsRebudes:[], userPositions:[]};
        $scope.delegacionsRebudes = [];
        $scope.userPositions = [];
        $scope.languageColumn = Language.getActiveColumn();
        //recullo delegacions meves//
        $scope.getDelegationsDash = function() {
            $scope.delegacioFeta = LoginService.getActiveDelegation();
            if($scope.delegacioFeta && $scope.delegacioFeta.startDelegationDate && $scope.delegacioFeta.startDelegationDate.time){
                $scope.delegacioFeta.startDelegationDate = new Date($scope.delegacioFeta.startDelegationDate.time);
            }
            if($scope.delegacioFeta && $scope.delegacioFeta.endDelegationDate && $scope.delegacioFeta.endDelegationDate.time){
                $scope.delegacioFeta.endDelegationDate = new Date($scope.delegacioFeta.endDelegationDate.time);
            }
            $scope.activeFeta = false;
            if($scope.delegacioFeta) {
                $scope.activeFeta = true;
            }
        };
        $scope.getDelegationsDash();

        //recullo delegacions cap a mi//
        $scope.getDelegatedDelegations = function() {
            RestService.findByUrl(apiSign.getDelegatedDelegations + "?delegated=" + $localStorage.LoggedUserID + "&date1=" + CommonService.formatDate(new Date(), true, false) + "&date2=" + CommonService.formatDate(new Date(), false, true)).then(function (data) {
                if (data && data.data && data.data.length > 0) {
                    if(!$scope.delegacionsRebudes){
                        $scope.delegacionsRebudes = [];
                    }else{
                        $scope.delegacionsRebudes.length = 0;
                    }
                    angular.forEach(JSOG.decode(data.data), function(value, key){
                        $scope.delegacionsRebudes.push(value);
                    });
                    $scope.activeRebudes = true;
                } else {
                    if(!$scope.delegacionsRebudes){
                        $scope.delegacionsRebudes = [];
                    }else{
                        $scope.delegacionsRebudes.length = 0;
                    }
                    $scope.activeRebudes = false;
                }
            });
        }
        $scope.getDelegatedDelegations();
        //recullo delegacions meves//
        $scope.addDelegation = function () {
            $scope.delegateModal.delgacioFeta = {};
            $scope.delegateModal.active = false;

            $scope.initDelegateModal();
            $timeout(function () {
                angular.element('#idUsuariDelegateModal').focus();
            })
        }
        
        $scope.getDelegations = function(item) {
        	if (item && item.currentTarget && item.currentTarget.id) {
                RestService.findOne('DelegationUser', item.currentTarget.id).then(function (data) {
                    if (data && data.data) {
                        var data2 = JSOG.decode(data.data);
                        $scope.delegateModal.delgacioFeta = data2;
                        if(new Date(CommonService.formatDate(new Date(data2.startDelegationDate), false, false)) <= new Date(CommonService.formatDate(new Date(), false, false))){
                            $scope.delegateModal.active = true;
                        }else {
                            $scope.delegateModal.active = false;
                        }
                    }else{
                        $scope.delegateModal.delgacioFeta = {};
                        $scope.delegateModal.active = false;
                    }
                    $scope.initDelegateModal();
                    $timeout(function () {
                        angular.element('#idUsuariDelegateModal').focus();
                    })
                }).catch(function (error) {
                    console.error(error);
                });
        	} else {
                RestService.findByUrl(apiSign.getDelegatorDelegations + "?delegator="+ $localStorage.LoggedUserID+"&date1=" + CommonService.formatDate(new Date(), false, true)).then(function (data) {
                    if (data && data.data && data.data.length > 0) {
                        var data2 = JSOG.decode(data.data);
                        $scope.delegateModal.delgacioFeta = data2[0];
                        if(new Date(CommonService.formatDate(new Date(data2[0].startDelegationDate), false, false)) <= new Date(CommonService.formatDate(new Date(), false, false))){
                            $scope.delegateModal.active = true;
                        }else {
                            $scope.delegateModal.active = false;
                        }
                    }else{
                        $scope.delegateModal.delgacioFeta = {};
                        $scope.delegateModal.active = false;
                    }
                    $scope.initDelegateModal();
                    $timeout(function () {
                        angular.element('#idUsuariDelegateModal').focus();
                    })
                });
        	}
        }

        $scope.initDelegateModal = function(){
            $scope.openInputStartDate = false;
            $scope.openInputEndDate = false;
            $scope.userPositions = $rootScope.LoggedUser.userPositions;
            $scope.delegateModal.userPositions = [];
            if($scope.delegateModal.delgacioFeta.id) {
                $scope.delegateModal.userName = $scope.delegateModal.delgacioFeta.delegatedUser.name + ' ' + $scope.delegateModal.delgacioFeta.delegatedUser.surename1 +  ($scope.delegateModal.delgacioFeta.delegatedUser.surename2 ? ' ' + $scope.delegateModal.delgacioFeta.delegatedUser.surename2 : '');
                $scope.delegateModal.user = $scope.delegateModal.delgacioFeta.delegatedUser;
                if(new Date($scope.delegateModal.delgacioFeta.startDelegationDate) <= new Date(CommonService.formatDate(new Date(), false, false))) {
                    $scope.delegateModal.minDate = new Date($scope.delegateModal.delgacioFeta.startDelegationDate);
                }else{
                    $scope.delegateModal.minDate = new Date(CommonService.formatDate(new Date(), false, false));
                }
                $scope.delegateModal.dateStart = new Date($scope.delegateModal.delgacioFeta.startDelegationDate);
                $scope.delegateModal.dateEnd = new Date($scope.delegateModal.delgacioFeta.endDelegationDate);
                $scope.delegateModal.error = false;
                $scope.delegateModal.errorDescripcio='';
                angular.forEach($scope.delegateModal.delgacioFeta.delegationUserPositions, function(value, key){
                	$scope.delegateModal.userPositions.push({id: value.userPosition.id, position: value.userPosition.position});
                });
            }else{
                $scope.delegateModal.userName = '';
                $scope.delegateModal.user = {};
                $scope.delegateModal.minDate = new Date();
                $scope.delegateModal.dateStart = new Date();
                $scope.delegateModal.dateEnd = new Date();
                $scope.delegateModal.error = false;
                $scope.delegateModal.errorDescripcio='';
                $scope.delegateModal.active=false;
                $scope.delegateModal.delgacioFeta = {};
            }
        }

        $scope.validateDelegation = function(){
            var disabled = false;
            if($scope.delegateModal.userName == null || $scope.delegateModal.userName == undefined || $scope.delegateModal.userName == ''){
                disabled = true;
            }else if($scope.delegateModal.dateStart == null || $scope.delegateModal.dateEnd == null || $scope.delegateModal.dateStart == undefined || $scope.delegateModal.dateEnd == undefined){
                disabled = true;
            }else if($scope.delegateModal.minDate > $scope.delegateModal.dateStart ){
                disabled = true;
            }else if($scope.delegateModal.dateStart > $scope.delegateModal.dateEnd){
                disabled = true;
            }
            return disabled;
        };

        $scope.validUser = function(){
            if($scope.delegateModal.userName.id){
                $scope.delegateModal.user = $scope.delegateModal.userName.user;
                $scope.delegateModal.userName = $scope.delegateModal.userName.value;
               trobat = true;
               if(!trobat){
                   $scope.delegateModal.userIdentifier = '';
                   $scope.delegateModal.user = {};
                   $scope.delegateModal.errorDescripcio = "global.error.delegation.errorUserNotFound";
                   $scope.delegateModal.error = true;
                   return false;
               }
            }else if($scope.delegateModal.user && !$scope.delegateModal.user.id){
                $scope.delegateModal.userName = '';
                $scope.delegateModal.user = {};
                $scope.delegateModal.errorDescripcio = "global.error.delegation.errorUserNotFound";
                $scope.delegateModal.error = true;
                return false;
            }else if($scope.delegateModal.user && $scope.delegateModal.user.id){
                var name = $scope.delegateModal.delgacioFeta.delegatedUser.name + ' ' + $scope.delegateModal.delgacioFeta.delegatedUser.surename1 +  ($scope.delegateModal.delgacioFeta.delegatedUser.surename2 ? ' ' + $scope.delegateModal.delgacioFeta.delegatedUser.surename2 : '');
                if($scope.delegateModal.userName != name){
                    $scope.delegateModal.userName = '';
                    $scope.delegateModal.user = {};
                    $scope.delegateModal.errorDescripcio = "global.error.delegation.errorUserNotFound";
                    $scope.delegateModal.error = true;
                    return false;
                }
            }
            return true;
        }

        $scope.createDelegation = function(){
            if($scope.validUser()) {
                $scope.showLoadingdiv = true;
                var delegationUserPositions = [];
                angular.forEach($scope.delegateModal.userPositions, function(value, key){
                	var delegationUser;
                	var delegationUserPosition;
                	if ($scope.delegateModal.delgacioFeta.id != null) {
                		delegationUser = $scope.delegateModal.delgacioFeta;
                		delegationUserPosition = $linq(delegationUser.delegationUserPositions).firstOrDefault(undefined, "x => x.userPosition.id == "+value.id);
                	}
                	
                	if (delegationUserPosition) {
                		delegationUserPositions.push({ id: delegationUserPosition.id, createdDate: delegationUserPosition.createdDate, delegationUser: {id: delegationUser.id}, userPosition: {id: delegationUserPosition.userPosition.id} });
                	} else {
                		delegationUserPositions.push({ userPosition: {id: value.id} });
                	}
                });

                var data = {
                    delegatorUser: {id: $rootScope.LoggedUser.id},
                    delegatedUser: {id: $scope.delegateModal.user.id},
                    startDelegationDate: new Date(CommonService.formatDate($scope.delegateModal.dateStart, false, false)),
                    endDelegationDate: new Date(CommonService.formatDate($scope.delegateModal.dateEnd, false, false)),
                    delegationUserPositions: delegationUserPositions
                }
                if ($scope.delegateModal.delgacioFeta.id != null && $scope.delegateModal.delgacioFeta.id != undefined) {
                    data.id = $scope.delegateModal.delgacioFeta.id;
                    data.createdDate = $scope.delegateModal.delgacioFeta.createdDate;
                } else {
                    data.createdDate = new Date();
                }
                var sendData = {delegationUser: angular.toJson(data)};
                RestService.insertOrUpdate(apiSign.insertDelegationUsers, sendData).then(function (dataGetDocument) {
                    $scope.delegateModal.delgacioFeta = JSOG.decode(dataGetDocument.data);
                    LoginService.ChangeDelegation($scope.delegateModal.delgacioFeta, false);
                    $scope.getDelegations();
                    $scope.getDelegationsDash();
                    $scope.getDelegatedDelegations();
                    $scope.showLoadingdiv = false;
                    $('#delegate-modal').modal('hide');
                }).catch(function (error) {
                    $scope.showLoadingdiv = false;
                });
            }
        };
        $rootScope.$on('modifyDelegations', function(event, args) {
            if(args && args.deleg){
            	LoginService.ChangeDelegation(args.deleg, args.deleted);
                $scope.getDelegations();
                $scope.getDelegationsDash();
                $scope.getDelegatedDelegations();
            }
        });
        $scope.deleteDelegation = function(){
            if($scope.delegateModal.delgacioFeta.id != null && $scope.delegateModal.delgacioFeta.id != undefined) {
                $scope.showLoadingdiv = true;
                var sendData = {idDelegation : $scope.delegateModal.delgacioFeta.id};
                RestService.insertOrUpdate(apiSign.deleteDelegationUsers, sendData).then(function (data) {
                    LoginService.ChangeDelegation($scope.delegateModal.delgacioFeta, true);
                    $scope.getDelegations();
                    $scope.getDelegationsDash();
                    $scope.getDelegatedDelegations();
                    $scope.showLoadingdiv = false;
                    $('#delegate-modal').modal('hide');
                }).catch(function () {
                    $scope.showLoadingdiv = false;
                });
            }else{
                $('#delegate-modal').modal('hide');
            }
        }
        //recullo usuaris//

        $scope.loadUsers = function(value){
            var valueOk = value;
            if(valueOk && valueOk.val){
                valueOk = valueOk.val;
            }
            return RestService.loadData('common','User',valueOk).then(function(dataSearch) {
                var usersLoad = [];
                if(dataSearch.data &&  dataSearch.data.content && dataSearch.data.content.length > 0){
                    angular.forEach(JSOG.decode(dataSearch.data.content), function(val, key) {
                        var name = val.name+" "+val.surename1;
                        if(val.surename2){
                            name = name+" "+val.surename2;
                        }
                        usersLoad.push({ 'id': val.id, 'user': val, 'value': name});
                    });
                }
                return usersLoad;
            }).catch(function(){
                return [];
            });
        }

        $scope.closeError = function(){
            $scope.delegateModal.error = false;
            $scope.delegateModal.errorDescripcio = "";
        }

    }]);




