/**
 * Created by osirvent on 20/01/2016.
 */
angular
    .module('annexaApp')
    .controller('AlertsController',['$scope', 'AlertsService', 'CommonAdminService','api','$rootScope','RestService', 'TaskFactory', '$state', function($scope, AlertsService, CommonAdminService, api, $rootScope, RestService, TaskFactory, $state) {
        $scope.page = 0;
        $scope.searchType = true;
        $scope.activeSeeAll = "bold";
        $scope.activeNoReaded = "normal";
        function getAlertsBySignInUser(alertTypeId){
            $scope.page = 0;
            $scope.moreAlerts = false;
            var contador = 0;
            AlertsService.getAlertsBySignInUser($scope.searchType, 1, $scope.page, alertTypeId)
                .then(function(data) {
                    if(data){
                        $scope.alertsToday = JSOG.decode(data);
                    }
                    var pending = 0;
                    RestService.findByUrl('./api/alerts/pending').then(function(data) {
                        $scope.pending = data.data;
                        contador ++;
                        if(contador == 3){
                            AlertsService.updateAlertsBySignInUser($rootScope.LoggedUser.id);
                        }
                    }).catch(function() {
                        $scope.pending = 0;
                        contador ++;
                        if(contador == 3){
                            AlertsService.updateAlertsBySignInUser($rootScope.LoggedUser.id);
                        }
                    });
                }).catch(function () {
                    contador ++;
                    if(contador == 3){
                        AlertsService.updateAlertsBySignInUser($rootScope.LoggedUser.id);
                    }
            });
            AlertsService.getAlertsBySignInUser($scope.searchType, 2, $scope.page, alertTypeId)
                .then(function(data) {
                    if(data){
                        $scope.alertsYesterday = JSOG.decode(data);
                    }
                    contador ++;
                    if(contador == 3){
                        AlertsService.updateAlertsBySignInUser($rootScope.LoggedUser.id);
                    }
                }).catch(function () {
            });
            AlertsService.getAlertsBySignInUser($scope.searchType, 3, $scope.page, alertTypeId)
                .then(function(data) {
                    if(data && data.content){
                        $scope.alertsOthers = JSOG.decode(data.content);
                        $scope.page = 1;
                    }
                    contador ++;
                    if(contador == 3){
                        AlertsService.updateAlertsBySignInUser($rootScope.LoggedUser.id);
                    }
                }).catch(function () {
            });
        }

        getAlertsBySignInUser('');


        $scope.searchTypeChange = function(type){
            $scope.searchType = type;
            if(type == true) {
                getAlertsBySignInUser($scope.userAlertType);
            }
            $scope.activeSeeAll = $scope.searchType ? "bold" : "normal";
            $scope.activeNoReaded = !$scope.searchType ? "bold" : "normal";
        }

        $scope.userAlertTypes = [
        	{ id: '', name: 'global.literals.all'},
        	{ id: 'DOCUMENT_SIGNATURE', name: 'global.literals.DOCUMENT_SIGNATURE'},
        	{ id: 'DILIGENCE', name: 'global.literals.DILIGENCE'},
        	{ id: 'PROPOSAL', name: 'global.literals.PROPOSAL'},
            { id: 'DOSSIER', name: 'global.literals.DOSSIER'},
            { id: 'TASK', name: 'global.literals.TASK'},
            { id: 'NOTIFICATION', name: 'global.literals.NOTIFICATION'},
            { id: 'ERROR', name: 'global.literals.ERROR'}
        ];

        $scope.userAlertType = '';

        $scope.userAlertTypeFilter = function(item) {
        	getAlertsBySignInUser(item.id);
        	$scope.userAlertType = item.id;
        }

        $scope.loadMore = function() {
            if(!$scope.moreAlerts && $scope.page > 0) {
                $scope.moreAlerts = true;
                AlertsService.getAlertsBySignInUser($scope.searchType, 3, $scope.page, $scope.userAlertType)
                    .then(function (data) {
                        if (data && data.content) {
                            data.content = JSOG.decode(data.content);
                            for (var i = 0; i < data.content.length; i++) {
                                $scope.alertsOthers.push(data.content[i]);
                            }
                            $scope.page = $scope.page + 1;
                        }
                        $scope.moreAlerts = false;
                    }).catch(function () {
                    $scope.moreAlerts = false;
                });
            }

        };

        $scope.loadMore();

        $scope.openTaskModal = function(id, assigned){
            var stateToCreateUrl = 'annexa.tasks.assignedTasks.edit';
            if(!assigned){
                stateToCreateUrl = 'annexa.tasks.createdTasks.edit';
            }
            var url = $state.href(stateToCreateUrl, {task: id});
            window.open(url,'_blank');
        };

    }]);